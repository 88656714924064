import { Suspense } from 'react';
import ExampleComponent from './ExampleComponent';
import { LoadingIndicator } from './LoadingIndicator';

const MainComponent = () => (
    <div className={'overflow-hidden display-flex flex-column'}>
        <Suspense fallback={<LoadingIndicator />}>
            <ExampleComponent />
        </Suspense>
    </div>
);

export default MainComponent;
